// extracted by mini-css-extract-plugin
export var blogDetailMain = "blogDetail-module--blog-detail-main---s014";
export var blogDetailWrapper = "blogDetail-module--blog-detail-wrapper--tsYs-";
export var profileWrapper = "blogDetail-module--profile-wrapper--EIHq5";
export var blogDetailNav = "blogDetail-module--blog-detail-nav--vCA90";
export var returnText = "blogDetail-module--return-text--iWTUm";
export var titleText = "blogDetail-module--title-text--RJuHi";
export var blogContent = "blogDetail-module--blog-content--QdvnX";
export var contentTitle = "blogDetail-module--content-title--2StJM";
export var contentDate = "blogDetail-module--content-date--d8D-j";
export var contentImage = "blogDetail-module--content-image--PTsp6";
export var contentSubtitle = "blogDetail-module--content-subtitle--Z9zlL";
export var contentBody = "blogDetail-module--content-body--ihnVv";