import React from 'react'
import BlogDetail from '../components/blogDetail'
import { graphql } from 'gatsby';
import SEO from '../components/seo';

const BlogPost = ({data}) => {
    return (
       <>
        <SEO 
            title={data.contentfulBlog.title}
            desctiption={data.contentfulBlog.description.childMarkdownRemark.excerpt}
            article={true}
        />
        <BlogDetail blog={data.contentfulBlog}/>
       </>
    )
}

export const data = graphql`
    query($id:String){
        contentfulBlog(id: {eq:$id}){
            id
            title
            slug
            date(formatString:"DD MMMM, YYYY")
            description{
                childMarkdownRemark{
                excerpt
                }
            }
            heroImage{
                gatsbyImageData
            }
            body{
                raw
            }
        }
    }`



export default BlogPost
