import React from 'react'
import {blogDetailMain,blogDetailWrapper,blogDetailNav,returnText,titleText,blogContent,contentTitle,contentDate,contentImage,contentSubtitle,contentBody,profileWrapper} from './blogDetail.module.css'
import Gap from './gap'
import { Link } from 'gatsby'
import Profile from './profile'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'
import {renderRichText} from 'gatsby-source-contentful/rich-text'
import {BLOCKS} from '@contentful/rich-text-types'


const BlogDetail = ({blog}) => {
    const image = getImage(blog.heroImage);
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node,children) => {
                return (children=="line-break")?<Gap height={20}/>:<p>{children}</p>
            }
        }
    }
    return (
        <div className={blogDetailMain}>
            <div className={blogDetailWrapper}>
                <div className={blogDetailNav}>
                    <Link className={returnText} to='/'><p>{"<"}</p></Link>    
                    <p className={titleText}>{blog.title}</p>    
                </div>  
                <Gap height={30}/>
                <div className={blogContent}>
                    <h1 className={contentTitle}>{blog.title}</h1>
                    <Gap height={5}/>
                    <p className={contentDate}>Author : Fahreza Isnanto - Dibuat pada {blog.date}</p>
                    <Gap height={10}/>
                    <GatsbyImage className={contentImage} image={image} alt='content-image'/>
                    <Gap height={10}/>
                    <p className={contentSubtitle}>{blog.title}</p>
                    <Gap height={10}/>
                    <div className={contentBody}>
                        {renderRichText(blog.body,options)}
                    </div>
                </div>
        </div>
        <div className={profileWrapper}>
            <Profile/>
            <Gap height={20}/>
        </div>
    </div>
    )
}

export default BlogDetail
